<template>
  <section class="tableSection t-end">
    <div class="tableHeading">
      <component :is="titleTag" class="tableHeading-title">
        卸相対取引・<span class="tableHeading-titleSuffix" :class="titleSuffixClass">{{
          titleSuffixText
        }}</span>
      </component>
      <p class="tableHeading-body">
        ENECHANGEが独自に保有しご紹介できる卸相対取引情報を提示しています
      </p>
    </div>

    <table class="otcTable">
      <thead class="otcTable-Head">
        <tr class="otcTable-row">
          <th class="otcTable-cell t-otcArea">エリア</th>
          <th class="otcTable-cell t-otcAmount">kW</th>
          <th class="otcTable-cell t-otcTimeText">時間帯</th>
          <th class="otcTable-cell t-otcTimeCodeCount">コマ数</th>
          <th class="otcTable-cell t-otcDays">曜日</th>
          <th class="otcTable-cell t-otcDurationText">期間</th>
          <th class="otcTable-cell t-otcUnitPriceRangeText">{{ otcUnitPriceRangeHeader }}単価</th>
          <th class="otcTable-cell t-otcPriceSumRangeText">
            総{{ otcUnitPriceRangeHeader }}額概算
          </th>
          <th class="otcTable-cell t-otcInquiry"></th>
        </tr>
      </thead>

      <tbody class="otcTable-Body">
        <tr v-for="(otc, index) in formattedOtcs" :key="index" class="otcTable-row">
          <td class="otcTable-cell t-otcArea">
            {{ otc.area.name }}
          </td>
          <td class="otcTable-cell t-otcAmount">
            {{ otc.amount | withDelimiter }}<span class="otcTable-unit">kW</span>
          </td>
          <td class="otcTable-cell t-otcTimeText">
            {{ otc.time_text }}
          </td>
          <td class="otcTable-cell t-otcTimeCodeCount">
            {{ otc.time_code_count }}
          </td>
          <td class="otcTable-cell t-otcDays">
            <span
              v-for="(day, key) in otc.days"
              :key="key"
              class="otcTable-dayIconWrapper"
              :class="day.class"
              ><span class="otcTable-dayIcon" :class="day.class">{{ day.text }}</span></span
            >
          </td>
          <td class="otcTable-cell t-otcDurationText">
            {{ otc.duration_text }}
          </td>
          <td class="otcTable-cell t-otcUnitPriceRangeText">
            {{ otc.unit_price_range_min | withDelimiter }}<span class="otcTable-unit">〜</span>
            {{ otc.unit_price_range_max | withDelimiter }}<span class="otcTable-unit">円</span>
          </td>
          <td class="otcTable-cell t-otcPriceSumRangeText">
            {{ otc.price_sum_range_min | withDelimiter }}<span class="otcTable-unit">〜</span>
            {{ otc.price_sum_range_max | withDelimiter }}<span class="otcTable-unit">円</span>
          </td>
          <td class="otcTable-cell t-otcInquiry">
            <a
              href="https://docs.google.com/forms/d/1-OLtlpwAZEpptPizKACoLVFk7ClWQTv9dYh_h5YDAbA/viewform"
              target="_blank"
              rel="noopener"
              class="otcTable-inquiryButton"
              >問い合わせ</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import withDelimiter from './functions/withDelimiter'

export default {
  name: 'PowerOtcTable',
  filters: {
    withDelimiter,
  },
  /**
   * @typedef {Object.<string, number, Array>} Props
   * @property {Number} [titleLevel] - 見出し要素のレベル
   * @property {boolean} isBuying - 買いである場合は true
   * @property {string[]} otcs - 卸相対取引情報のリスト
   */
  props: {
    titleLevel: {
      type: Number,
      required: false,
      default: 3,
    },
    isBuying: {
      type: Boolean,
      required: true,
    },
    otcs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    /**
     * 見出し要素のタグをレベルから生成
     * @returns {string}
     */
    titleTag() {
      return `h${this.titleLevel}`
    },
    /**
     * (買い|売り)
     * @returns {string}
     */
    titleSuffixText() {
      return this.isBuying ? '買い' : '売り'
    },
    /**
     * (買い|売り)の文字色を指定するclass
     * @returns {string}
     */
    titleSuffixClass() {
      return this.isBuying ? 't-buy' : 't-sell'
    },
    /**
     * (買電単価|売電単価)
     * @returns {string}
     */
    otcUnitPriceRangeHeader() {
      return `${this.isBuying ? '買' : '売'}電`
    },
    /**
     * 曜日列に表示するアイコンの表示用情報
     * @returns {{saturday: {text: string, class: string}, weekdays: {text: string, class: string}, holiday: {text: string, class: string}}}
     */
    dayComponents() {
      return {
        weekdays: {
          class: 't-weekdays',
          text: '平日',
        },
        saturday: {
          class: 't-saturday',
          text: '土',
        },
        holiday: {
          class: 't-holiday',
          text: '日・祝',
        },
      }
    },
    /**
     * 卸相対取引情報に曜日アイコンを付加して返却
     * @returns {Object[]}
     */
    formattedOtcs() {
      return this.otcs.map((otc) => {
        return {
          ...otc,
          days: this.getDays(otc),
        }
      })
    },
  },
  methods: {
    /**
     * 卸相対取引情報から曜日列に教示するアイコンリストを生成
     * @param {Object} otc - 卸相対取引情報
     * @returns {[]}
     */
    getDays(otc) {
      const days = []
      if (otc.has_weekdays) days.push(this.dayComponents.weekdays)
      if (otc.has_saturday) days.push(this.dayComponents.saturday)
      if (otc.has_holiday) days.push(this.dayComponents.holiday)
      return days
    },
  },
}
</script>

<style lang="scss" scoped>
@import './css/vue_modules/table_section';
@import './css/vue_modules/content_heading';
@import './css/vue_modules/table_heading';
@import './css/vue_modules/otc_table';
@import './css/vue_modules/otc_table_otc';
</style>
