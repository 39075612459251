<template>
  <div>
    <PowerOtcTable v-if="otcsBuy[0]" :is-buying="true" :otcs="otcsBuy" />
    <PowerOtcTable v-if="otcsSell[0]" :is-buying="false" :otcs="otcsSell" />
  </div>
</template>

<script>
import PowerOtcTable from './PowerOtcTable.vue'

export default {
  components: {
    PowerOtcTable,
  },
  /**
   * @typedef {Object.<Array>} Props
   * @property {Object[]} otcsBuy - 卸相対取引・買いのリスト
   * @property {Object[]} otcsSell - 卸相対取引・売りのリスト
   */
  data() {
    return {
      otcsBuy: gon.power_otcs_buy,
      otcsSell: gon.power_otcs_sell,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './css/vue_modules/table_section';
</style>
